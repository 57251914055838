import React, { useState, useRef } from "react"
import MainLayout from "../components/MainLayout"
import { graphql, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { contactForm, contactMessage, contactMessageWarning, recaptcha } from "../styles/contact.module.css"
import { Helmet } from "react-helmet"
import { OutboundLink } from "gatsby-plugin-google-analytics"
// import { getContacts } from "../utils/contacts"
import { Axios, firebase } from "../utils/firebase"
import ReCAPTCHA from "react-google-recaptcha";


export default function Contact({ data }) {
  const {
    contactCountryCode,
    contactNumber,
    contactWhatsapp,
  } = data.site.siteMetadata

  const [formData, setFormData] = useState({})
  const [validCaptcha, setValidCaptcha] = useState(null)
  const captcha = useRef(null);
  const disabled = true;

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    return;

    if (captcha.current.getValue()) {
      setValidCaptcha(true);
      sendEmail()
      setFormData({
        name: '',
        phone: '',
        email: '',
        message: '',
      })
      // redirect to thanks page
      navigate("/thanks")
    } else {
      setValidCaptcha(false);
    }
  }

  // verified recaptcha 
  const onChange = () => {
    if (captcha.current.getValue()) {
      // valid captcha
      setValidCaptcha(true);
    }
  }

  const sendEmail = () => {
    Axios.post(
      'https://us-central1-linguolab-2a7cc.cloudfunctions.net/submit',
      formData
    )
      .then(res => {
        firebase.firestore().collection('contacts').add({
          name: formData.name,
          email: formData.email,
          message: formData.message,
          phone: formData.phone,
          createdAt: new Date(),
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <MainLayout>
      <Helmet>
        <title>Linguolab Contacto</title>
        <meta
          name="description"
          content="Contáctanos para obtener más información de nuestros cursos"
        ></meta>
        <meta
          name="keywords"
          content="linguolab, aprender idiomas, aprender, idiomas, cursos, clases, plataforma, online, zoom, virtual, linguo, ingles, inglés, frances, francés, aleman, alemán"
        />
        <html lang="es" />
      </Helmet>
      <div className="page-header">
        <div className="page-header-titles">
          <h2>Contacto</h2>
        </div>
        <GatsbyImage
          image={data.titleImage.childImageSharp.gatsbyImageData}
          className="page-header-background"
          alt="thumb"
          objectFit="cover"
          objectPosition="25% 50%"
        />
      </div>
      <div className="layout">
        <div className={`page-content`}>
          <div className={`${contactForm}`}>
            <form
              onSubmit={handleSubmit}
              name="contact v1"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact v1" />
              {/* prevent spam from bots */}
              <div hidden>
                <input name="bot-field" />
              </div>

              <p className={contactMessageWarning}>
                Email deshabilitado temporalmente
              </p>
              <input 
                type="text" 
                name="name" 
                placeholder="NOMBRE *"
                onChange={updateInput}
                value={formData.name || ''}
                disabled={disabled}
                required />
              <input 
                type="email" 
                name="email" 
                placeholder="EMAIL *" 
                onChange={updateInput}
                value={formData.email || ''}
                disabled={disabled}
                required />
              <input 
                type="number" 
                name="phone" 
                placeholder="TELÉFONO" 
                onChange={updateInput}
                disabled={disabled}
                value={formData.phone || ''} />
              {/* <textarea
                name="message"
                cols="30"
                rows="10"
                placeholder="MENSAJE *"
                onChange={updateInput}
                disabled={disabled}
                value={formData.message || ''}
                required
              ></textarea> */}
              {
                !disabled &&
                <div className={recaptcha}>
                  <ReCAPTCHA
                    ref={captcha}
                    sitekey="6LcDL_YaAAAAACDTI-TxYtUh9NUtjitzxrrSDIce"
                    onChange={onChange}
                  />
                </div>
              }
              { !disabled && validCaptcha === false && 
                <p style={{color: 'red'}}> 
                  Por favor completa tus datos y el Captcha para continuar
                </p>
              }
              <button type="submit" className="button" disabled>
                Enviar
              </button>
            </form>
            <p className={contactMessage}>
              También puedes contactarnos por {" "}
              <OutboundLink
                target="_new"
                href={`https://wa.me/${contactWhatsapp}`}
              >
                Whatsapp
              </OutboundLink>
            </p>
            <p className={contactMessage}>
              O llamarnos al teléfono {" "}
                <OutboundLink
                href={`tel:${contactNumber}`}>
                  +{contactCountryCode} {contactNumber}
              </OutboundLink>
            </p>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
export const query = graphql`
  query ContactPage {
    site {
      siteMetadata {
        contactEmail
        contactNumber
        contactCountryCode
        contactWhatsapp
      }
    }
    titleImage: file(relativePath: { eq: "featured/title-1.png" }) {
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`
